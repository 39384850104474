import { RouteObject } from 'react-router-dom'
import Home from '@pages/Home'
import Middleware from '@middlewares/index'
import Signin from '@pages/Signin'
import UserInvite from '@pages/invite'
import Expired from '@components/Expired'
import Error from '@components/Error'
import NewUser from '@pages/signup/NewUser'
import useSentry from '@hooks/useSentry'
import InvalidToken from '@components/InvalidToken'
import ForgetPage from '@pages/Forget'
import ChangePassword from '@pages/Forget/ChangePassword'
import TallySuccess from '@components/Tally/TallySuccess'

const Routes = () => {
  const { sentryRoutes } = useSentry()

  const routes: RouteObject[] = [
    {
      path: '/',
      element: <Middleware />,
      children: [
        {
          index: true,
          element: <Home />,
        },

        {
          path: 'signin',
          element: <Signin />,
          children: [
            {
              index: true,
              element: <Signin />,
            },
            {
              path: ':appName',
              element: <Signin />,
            },
          ],
        },
        // {
        //   path: 'signin/:appName',
        //   element: <Signin />,
        // },
      ],
    },
    {
      path: '/user-invite/:uuid/:appName',
      element: <UserInvite />,
    },
    {
      path: '/user-invite/:uuid/:appName/:orgId',
      element: <UserInvite />,
    },
    {
      path: '/expired',
      element: <Expired />,
    },
    {
      path: '/error',
      element: <Error />,
    },
    {
      path: '/invalidate',
      element: <InvalidToken />,
    },
    {
      path: '/user-invite/:uuid/new-user/:appName/',
      element: <NewUser />,
    },
    {
      path: '/forget',
      element: <ForgetPage />,
    },
    {
      path: '/changepassword/:uuid',
      element: <ChangePassword />,
    },
    {
      path: '/tally-login-success',
      element: <TallySuccess />,
    },
  ]

  return sentryRoutes(routes)
}

export default Routes
