import { queries } from '@constants/queryKeys'
import { useQuery } from '@tanstack/react-query'
import * as API from './api'
import { UseQueryType } from '@contracts/query'
import { ICountry } from '@contracts/models/master'
import { MASTERS_STALE_TIME } from '@config/app.config'
import { MobileWithFlagText } from 'yolo-design/components'
import React from 'react'

export const useCountry: UseQueryType<ICountry[]> = (options) =>
  useQuery({
    queryKey: queries.master.country.queryKey,
    queryFn: API.getCountry,
    staleTime: MASTERS_STALE_TIME,
    ...options,
  })

export const useCountryWithFlag: () => {
  id: string
  label: React.ReactNode
}[] = () => {
  const { data = [] } = useCountry()

  return data.map((el) => ({
    id: String(el.id),
    label: (
      <MobileWithFlagText
        key={el?.id}
        flag={el?.emoji}
        text={String(el?.phone_code)}
      />
    ),
    phone_code: el.phone_code,
  }))
}
