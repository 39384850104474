import { queries } from '@constants/queryKeys'
import { useMutation, useQuery } from '@tanstack/react-query'
import * as API from './api'
import {
  UseMutationType,
  UseQueryDataType,
  UseQueryType,
} from '@contracts/query'
import {
  IInviteInfo,
  INewRegisterRep,
  INewRegisterReq,
  IUserInfo,
} from '@contracts/models/user'

export const useUserInfo: UseQueryType<IUserInfo> = (options) =>
  useQuery({
    queryKey: queries.user.info.queryKey,
    queryFn: API.getUserInfo,
    ...options,
  })

export const useUserInviteInfo: UseQueryDataType<
  IInviteInfo,
  { uuid: string; orgId?: string }
> = (options, { uuid }) => {
  return useQuery({
    queryKey: queries.user.invite(uuid).queryKey,
    queryFn: () => API.getInviteUser(uuid),
    ...options,
  })
}

export const useUserStoreInviteInfo: UseQueryDataType<
  IInviteInfo,
  { uuid: string; orgId: string }
> = (options, { uuid, orgId }) => {
  return useQuery({
    queryKey: queries.user.storeInvite(uuid, orgId).queryKey,
    queryFn: () => API.getStoreInviteUser(uuid, orgId),
    ...options,
  })
}

export const useAcceptInvite: UseQueryDataType<
  IInviteInfo,
  { uuid: string }
> = (options, { uuid }) =>
  useQuery({
    queryKey: queries.user.accept(uuid).queryKey,
    queryFn: () => API.getInviteUserAccept(uuid),
    ...options,
  })
export const useStoreAcceptInvite: UseQueryDataType<
  IInviteInfo,
  { uuid: string; orgId: string }
> = (options, { uuid, orgId }) =>
  useQuery({
    queryKey: queries.user.storeAccept(uuid, orgId).queryKey,
    queryFn: () => API.getStoreInviteUserAccept(uuid, orgId),
    ...options,
  })

export const useRegisterUser: UseMutationType<
  Partial<INewRegisterRep>,
  { reqData: INewRegisterReq; id: string }
> = (options) => useMutation(API.postRegisterUser, options)
export const useStoreRegisterUser: UseMutationType<
  Partial<INewRegisterRep>,
  { reqData: INewRegisterReq; id: string; orgId: string }
> = (options) => useMutation(API.postStoreRegisterUser, options)
