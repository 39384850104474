import LogoLayout from './Layout/LogoLayout'
import { Text } from 'yolo-design/components'
import { useUserInfo } from '@services/user'
import { Color } from 'yolo-design/constants'

const InvalidToken = () => {
  const { data } = useUserInfo()
  return (
    <>
      <LogoLayout>
        <div className="d-flex flex-column justify-content-center w-100 align-items-center h-80">
          <div>
            {/* <Setting style={{ width: '100px', height: '100px' }} size="large" /> */}
          </div>
          <div className="w-100 d-flex flex-column justify-content-center align-items-center">
            {data?.email ? (
              <>
                {' '}
                <Text
                  category="label"
                  value={`👤 ${data?.email}  `}
                  color={Color?.blue?.just_blue}
                />
                <Text
                  category="label"
                  value={`user already login in this machine `}
                  color={Color?.gray_shades?.gray_500}
                />
              </>
            ) : (
              <></>
            )}

            <Text
              category="heading"
              value={`you dont have access this  invite link 🚫`}
              className="text-center mt-8"
              size="medium"
            />
          </div>
        </div>
      </LogoLayout>
    </>
  )
}

export default InvalidToken
