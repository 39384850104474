import React from 'react'
import * as Sentry from '@sentry/react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  useRoutes,
} from 'react-router-dom'
import { ENV, SENTRY_APP_NAME } from '@config/app.config'

const useSentry = () => {
  const isLocal = ENV.ENV_NAME === 'local'
  const isProd = ENV.ENV_NAME === 'prod'

  const init = () => {
    !isLocal &&
      Sentry.init({
        dsn: ENV.SENTRY_DSN,
        environment: ENV.ENV_NAME,
        initialScope: {
          tags: {
            app_name: SENTRY_APP_NAME,
          },
        },
        beforeBreadcrumb: (breadcrumb) => {
          return breadcrumb.category === 'ui.click' ? null : breadcrumb
        },
        integrations: [
          new Sentry.BrowserTracing({
            // Set tracePropagationTargets to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
              'localhost',
              /^https:\/\/yourserver\.io\/api/,
            ],
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
              React.useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes
            ),
          }),
          new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: isProd ? 0.1 : 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      })
  }

  const sentryRoutes = isLocal ? useRoutes : Sentry.wrapUseRoutes(useRoutes)

  const setUser = ({ email, id }: { email: string; id: number }) =>
    Sentry.setUser({ email, id: String(id) })

  return { init, sentryRoutes, Sentry, setUser }
}

export default useSentry
