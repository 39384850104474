const URL = import.meta.env.VITE_INVOICE_WEB_BASE_URL
const FIELD_FORCE = import.meta.env.VITE_FIELDFORCE_WEB_BASE_URL
const MANUFACTURE_URL = import.meta.env.VITE_MANUFACTURE_WEB_BASE_URL
const INVENTORY_URL = import.meta.env.VITE_INVENTORY_WEB_BASE_URL
const COMMERCE_URL = import.meta.env.VITE_COMMERCE_WEB_BASE_URL
export const NAVIGATE = {
  default: URL,
  invoice: URL,
  fieldforce: FIELD_FORCE,
  manufacture: MANUFACTURE_URL,
  inventory: INVENTORY_URL,
  store: COMMERCE_URL,
}
