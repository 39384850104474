import { ICountry } from '@contracts/models/master'
import { GET } from '@lib/AxiosClient'

export const getCountry = (): Promise<ICountry[]> =>
  GET({
    url: 'public/country/',
    headers: {
      Authorization: null,
    },
  })
