import LogoLayout from '@components/Layout/LogoLayout'
import { IChangePasswordPost } from '@contracts/models/forget'
import { useChangePassword } from '@services/forget'
import { Col, Form, Row, message } from 'antd'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Button,
  Checkbox,
  FormInputPassword,
  FormWrapper,
  Text,
} from 'yolo-design/components'
import { Color } from 'yolo-design/constants'

const ChangePassword = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { uuid } = useParams()
  const [checked, setChecked] = useState(false)

  /*
  TODO: below const ChangePassword name is same as component name. pls change.
  */
  const handleChangePassword = useChangePassword({
    onSuccess: () => {
      message.success('Password updated successfully')
      navigate('/signin')
    },
    onError(error: any) {
      form.setFields([
        {
          name: 'password',
          errors: error?.response?.data?.error?.message,
        },
      ])
    },
  })
  const handleFormFinish = () => {
    const val = form.getFieldsValue()
    const postData: IChangePasswordPost = {
      ...val,
      uuid: uuid,
    }
    handleChangePassword.mutate(postData)
  }
  return (
    <>
      <LogoLayout>
        <div className="d-flex justify-content-center align-items-center h-max-content w-100 flex-column">
          <Text
            category="heading"
            size="large"
            weight="bold"
            value="Change your password"
            color="#000"
          />

          <FormWrapper
            antdFormProps={{
              form: form,
              layout: 'vertical',
              /*
  TODO: change inline style to classname
  */
              style: { width: '100%', marginTop: '20px' },
              className: 'd-flex justify-content-center',
              onFinish: handleFormFinish,
            }}
          >
            <Row gutter={[20, 0]} style={{ width: '100%' }}>
              <Col span={24}>
                <FormInputPassword
                  name={'password'}
                  title="Password"
                  inputType={checked ? 'name' : 'password'}
                  required
                />
                <Text
                  category="label"
                  size="small"
                  weight="medium"
                  value="Use 8 or more characters with a mix of letters, numbers & symbols"
                  color={Color.gray_shades.gray_700}
                  style={{ marginTop: '-10px' }}
                />
                <Checkbox
                  setIsChecked={setChecked}
                  isChecked={checked}
                  handleCheckboxChange={() => setChecked(!checked)}
                  label="Show Password"
                  /*
  TODO: change inline style to classname
  */
                  antdCheckboxProps={{ style: { marginTop: '20px' } }}
                />
              </Col>
              <Col span={24}>
                <Button
                  antdButtonProps={{ htmlType: 'submit' }}
                  /*
  TODO: change inline style to classname
  */
                  style={{ marginTop: '30px' }}
                  block
                  type="solid"
                  size="large"
                  label="Change Password"
                />
              </Col>
            </Row>
          </FormWrapper>
        </div>
      </LogoLayout>
    </>
  )
}
export default ChangePassword
