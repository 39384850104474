import React from 'react'
import { Form, Row, Col } from 'antd'
import {
  Text,
  Color,
  Button,
  FormInputGroup,
  TextGroup,
  FormWrapper,
} from 'yolo-design'
import { PasswordWithConfirm } from 'yolo-design/organisms'

import { useNavigate, useSearchParams } from 'react-router-dom'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import LogoLayout from '@components/Layout/LogoLayout'
import {
  useRegisterUser,
  useStoreRegisterUser,
  useUserInviteInfo,
  useUserStoreInviteInfo,
} from '@services/user'
import { SALUTATION_LIST } from '@constants/master'
import { useCountryWithFlag } from '@services/master'
import { NAVIGATE } from '@utils/index'
import { AppType } from '@contracts/index'
import getDeviceId from '@utils/deviceId'
import { setPasscode } from '@utils/auth'

const NewUser = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const countryList = useCountryWithFlag()
  const { appName = '', uuid } = useParams()
  const [searchParams] = useSearchParams()

  const [isNameFieldError, setIsNameFieldError] = useState(false)
  const [isMobileFieldError, setIsMobileFieldError] = useState(false)
  const orgId = searchParams.get('orgId') || ''

  const invtieInfo = orgId ? useUserStoreInviteInfo : useUserInviteInfo

  const { data } = invtieInfo(
    {
      retry: false,
      onError() {
        navigate('/error')
      },
    },
    {
      uuid: String(uuid),
      orgId: String(orgId),
    }
  )

  const postNewUser = useRegisterUser({
    onSuccess: (data) => {
      if (data?.passcode) {
        setPasscode(data?.passcode)
      }

      window.location.href = `${NAVIGATE[appName as AppType]}${data?.passcode}`
    },
    onError: (err: any) => {
      if (err?.response?.data?.error?.username) {
        form.setFields([
          {
            name: 'email',
            errors: [err?.response?.data?.error?.username[0]],
          },
        ])
      }
      if (err?.response?.data?.error?.password) {
        form.setFields([
          {
            name: 'password',
            errors: [err?.response?.data?.error?.password[0]],
          },
          {
            name: 'confirm_password',
            value: '',
          },
        ])
      }
    },
  })

  const storeNewUser = useStoreRegisterUser({
    onSuccess: (data) => {
      if (data?.passcode) {
        setPasscode(data?.passcode)
      }

      window.location.href = `${NAVIGATE[appName as AppType]}${orgId}`
    },
    onError: (err: any) => {
      if (err?.response?.data?.error?.username) {
        form.setFields([
          {
            name: 'email',
            errors: [err?.response?.data?.error?.username[0]],
          },
        ])
      }
      if (err?.response?.data?.error?.password) {
        form.setFields([
          {
            name: 'password',
            errors: [err?.response?.data?.error?.password[0]],
          },
          {
            name: 'confirm_password',
            value: '',
          },
        ])
      }
    },
  })
  const handleFormFinish = () => {
    const val = form.getFieldsValue()
    const postData = {
      ...val,
      accepted_terms: true,
      device_id: getDeviceId(),
      email: data?.user,
    }
    if (orgId) {
      storeNewUser.mutate({ id: String(uuid), reqData: postData, orgId: orgId })
    } else {
      postNewUser.mutate({ id: String(uuid), reqData: postData })
    }
  }

  return (
    <>
      {' '}
      <LogoLayout>
        <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
          <Text
            category="heading"
            size="large"
            weight="bold"
            value="Create your Yolo account"
            color="#000"
          />
          <TextGroup
            style={{ marginTop: '8px' }}
            category="body"
            size="medium"
            weight="medium"
            list={[
              { value: 'Account will be linked to the invited email address' },
            ]}
          />
          <Text
            category="body"
            size="medium"
            weight="medium"
            value={data?.user}
            color={Color?.blue?.just_blue}
          />

          <FormWrapper
            antdFormProps={{
              form: form,
              layout: 'vertical',
              // style: { width: '100%', marginTop: '20px' },
              className: 'w-100 mt-20',
              onFinish: handleFormFinish,
              onFieldsChange: (field: any) => {
                setIsNameFieldError(
                  form.getFieldError('user_name').length ? true : false
                )
                setIsMobileFieldError(
                  form.getFieldError('phone').length ? true : false
                )

                if (field[0].name[0] === 'confirm_password') {
                  form.setFields([
                    {
                      name: 'password',
                      errors: [],
                    },
                  ])
                }
              },
              validateTrigger: 'onBlur',
            }}
          >
            {/* TODO: change inline style to classname */}
            <Row gutter={[20, 0]} className="w-100">
              <Col span={24}>
                <FormInputGroup
                  type="text"
                  form={form}
                  prefixName={'salutation'}
                  name={'user_name'}
                  title="Full Name"
                  prefixOptions={SALUTATION_LIST}
                  defaultPrefix={'2'}
                  required
                  isTextInputError={isNameFieldError}
                />
              </Col>
              <Col span={24}>
                <FormInputGroup
                  type="number"
                  form={form}
                  prefixName={'country'}
                  name={'phone'}
                  title="Mobile Number"
                  prefixOptions={countryList}
                  defaultPrefix={'101'}
                  required
                  isTextInputError={isMobileFieldError}
                  showPrefixSearch
                  filterOption={(input, option: any) =>
                    String(option?.children?.props?.text || '').includes(input)
                  }
                />
              </Col>

              <PasswordWithConfirm name={'password'} />

              <Col span={24}>
                <Button
                  antdButtonProps={{
                    htmlType: 'submit',
                    loading: postNewUser?.isLoading,
                    className: 'mt-32',
                  }}
                  block
                  type="solid"
                  size="large"
                  label="Create Account"
                />
              </Col>
              {/*
  TODO: change inline style to classname
  */}
              <Col span={24}>
                <TextGroup
                  // style={{ marginTop: '16px', justifyContent: 'center' }}
                  className="mt-16"
                  category="body"
                  size="small"
                  weight="medium"
                  list={[
                    {
                      value: 'by clicking, I accept the',
                      color: Color.gray_shades.gray_700,
                    },
                    {
                      value: 'Terms & Condition',
                      isLink: true,
                      link: 'https://invoice.yoloworks.com/terms-of-service.html',
                    },
                    { value: '&', color: Color.gray_shades.gray_700 },
                    {
                      value: 'Privacy Policy',
                      isLink: true,
                      link: 'https://invoice.yoloworks.com/privacy-policy.html',
                    },
                  ]}
                />
              </Col>
            </Row>
          </FormWrapper>
        </div>
      </LogoLayout>
    </>
  )
}
export default NewUser
