import useSentry from '@hooks/useSentry'
import React, { FC, PropsWithChildren, useEffect, useState } from 'react'
import { withProfiler } from '@sentry/react'

const Sentry: FC<PropsWithChildren> = ({ children }) => {
  const { init } = useSentry()
  const [isFirst, setIsFirst] = useState(true)

  useEffect(() => {
    if (isFirst) {
      init()

      setIsFirst(false)
    }
  }, [])

  return <>{children}</>
}

export default withProfiler(Sentry)
