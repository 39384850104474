import { TOKEN_KEY, ORG_KEY } from '@constants/index'
import { getCookie, removeCookie } from './cookie'
import {
  removeAuthHeader,
  removeOrgHeader,
  setAuthHeader,
  setOrgHeader,
} from '@lib/AxiosClient'
import { ENV } from '@config/app.config'

export const getPasscode = () => getCookie(TOKEN_KEY)

const oneYearFromNow = () =>
  new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toUTCString()

export const setPasscode = (value: string) => {
  const cookie = `${TOKEN_KEY}=${value}; domain=${
    ENV.WEB_DOMAIN
  }; path=/; hostOnly=false; secure=true; Expires=${oneYearFromNow()};`
  document.cookie = cookie

  setAuthHeader(value)
}

export const removePasscode = () => {
  removeCookie(TOKEN_KEY)
  removeAuthHeader()
}

export const getOrgId = () => getCookie(ORG_KEY)

export const setOrgId = (value: string) => {
  const cookie = `${ORG_KEY}=${value}; path=/; domain=${
    ENV.WEB_DOMAIN
  }; hostOnly=true; secure=true; Expires=${oneYearFromNow()};`
  document.cookie = cookie

  setOrgHeader(value)
}

export const removeOrgId = () => {
  removeCookie(ORG_KEY)
  removeOrgHeader()
}

export const isFromMobile = () =>
  window.navigator.userAgent.startsWith('yolo-app/')
