import { FC, PropsWithChildren } from 'react'
import { yoloWorksLogo } from 'yolo-design/assets'
import cn from 'classnames'
import { isFromMobile } from '@utils/auth'

const LogoLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div
      style={{ width: '100vw', height: '100vh' }}
      className="d-flex flex-column align-items-center"
    >
      <div
        className={cn('d-flex w-100 px-24 py-12', {
          'justify-content-center': isFromMobile(),
        })}
      >
        <img
          src={yoloWorksLogo}
          alt={'yolo-works'}
          style={{ width: 'auto', height: '36px' }}
        />
      </div>
      <div
        style={{ width: '438px' }}
        className={cn('d-flex flex-column px-20', {
          'px-32': isFromMobile(),
        })}
      >
        {children}
      </div>
    </div>
  )
}

export default LogoLayout
