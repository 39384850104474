import {
  IChangePasswordPost,
  IChangePasswordRes,
  IForgetPost,
  IForgetRes,
} from '@contracts/models/forget'

import { POST } from '@lib/AxiosClient'

export const postForgetPassword = (
  data: IForgetPost
): Promise<Partial<IForgetRes>> =>
  POST({
    url: `/account/forget-password-uuid/`,
    data,
  })

export const postChangePassword = (
  data: IChangePasswordPost
): Promise<Partial<IChangePasswordRes>> =>
  POST({
    url: `/account/change-password/`,
    data,
  })
