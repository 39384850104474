import { ILoginReq } from '@contracts/models/auth'
import { IUserInfo } from '@contracts/models/user'
import { POST } from '@lib/AxiosClient'

export const postSignin = (data: ILoginReq): Promise<IUserInfo> =>
  POST({
    url: '/account/login/',
    headers: { Authorization: null, Organization: null },
    data,
  })
