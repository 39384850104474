import LogoLayout from '@components/Layout/LogoLayout'
import { getPasscode } from '@utils/auth'
import { NAVIGATE } from '@utils/index'
import React from 'react'
import { Button } from 'yolo-design/components'

const Home = () => {
  const token = getPasscode()

  const navigateToInvoive = () => {
    window.location.href = `${NAVIGATE['invoice']}${token}`
  }

  const navigateToFieldForce = () => {
    window.location.href = `${NAVIGATE['fieldforce']}${token}`
  }

  const navigateToManufacturer = () => {
    window.location.href = `${NAVIGATE['manufacture']}`
  }
  const navigateToInventory = () => {
    window.location.href = `${NAVIGATE['inventory']}`
  }
  const navigateToCommerce = () => {
    window.location.href = `${NAVIGATE['commerce']}`
  }
  return (
    <LogoLayout>
      <div className="d-flex w-100 gap-4">
        <Button
          label="Invoice"
          antdButtonProps={{ onClick: navigateToInvoive }}
        />
        <Button
          label="Field Force"
          antdButtonProps={{ onClick: navigateToFieldForce }}
        />
        <Button
          label="Manufacturer"
          antdButtonProps={{ onClick: navigateToManufacturer }}
        />
        <Button
          label="Inventory"
          antdButtonProps={{ onClick: navigateToInventory }}
        />
        <Button
          label="Commerce"
          antdButtonProps={{ onClick: navigateToCommerce }}
        />
      </div>
    </LogoLayout>
  )
}

export default Home
