import LogoLayout from '@components/Layout/LogoLayout'
import { Text } from 'yolo-design/components'

const TallySuccess = () => {
  return (
    <LogoLayout>
      <div className="d-flex flex-column justify-content-center w-100 align-items-center h-80">
        <div className="w-100 d-flex justify-content-center">
          <Text
            category="heading"
            value={`Login Successful ✅`}
            className="text-center mt-8"
            size="medium"
          />
        </div>
      </div>
    </LogoLayout>
  )
}
export default TallySuccess
