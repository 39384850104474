import { ENV } from '@config/app.config'
import { AppType } from '@contracts/index'

export const TOKEN_KEY = 'Passcode'
export const ORG_KEY = 'OrgId'

export const APP_URL: Record<AppType, string> = {
  invoice: ENV.INVOICE_WEB_BASE_URL,
  fieldforce: ENV.FIELDFORCE_WEB_BASE_URL,
  manufacture: ENV.MANUFACTURE_WEB_BASE_URL,
  commerce: ENV.COMMERCE_WEB_BASE_URL,
}

export const getSignupRedirectUrl = (app: AppType) => {
  const App = app || 'invoice'

  return `${APP_URL[App]}user/register`
}
