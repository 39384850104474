import LogoLayout from '@components/Layout/LogoLayout'
import { AppType } from '@contracts/index'
import {
  useAcceptInvite,
  useStoreAcceptInvite,
  useUserInfo,
  useUserInviteInfo,
  useUserStoreInviteInfo,
} from '@services/user'
import { getPasscode, removeOrgId, removePasscode } from '@utils/auth'
import { NAVIGATE } from '@utils/index'
import nProgress from 'nprogress'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const UserInvite = () => {
  const navigate = useNavigate()
  const { appName = '', uuid, orgId } = useParams()
  const [email, setEmail] = useState('')
  const passcode = getPasscode()
  const inviteParams = {
    uuid: String(uuid),
    ...(orgId && { orgId: String(orgId) }),
  }
  const AcceptInfo =
    appName === 'store' ? useStoreAcceptInvite : useAcceptInvite
  const commonSuccessHandler = (data: any) => {
    if (passcode) {
      setEmail(data?.user)
      userInfo()
    } else {
      if (data?.is_new_user) {
        const path = orgId
          ? `/user-invite/${data?.uuid}/new-user/${appName}/?orgId=${data.organization}`
          : `/user-invite/${data?.uuid}/new-user/${appName}`
        navigate(path)
        nProgress.done()
      } else {
        const path = orgId
          ? `/signin/${appName}?uuid=${data?.uuid}&orgId=${data.organization}`
          : `/signin/${appName}?uuid=${data?.uuid}`
        navigate(path)
      }
    }
  }
  const commonErrorHandler = (err: any) => {
    nProgress.done()
    if (err?.response?.status === 403) {
      navigate('/expired')
    }
  }

  const { refetch: userInfo } = useUserInfo({
    enabled: !!passcode && !!email,

    onSuccess: (data) => {
      if (data?.email === email) {
        InviteAcceptRefetch()
      } else {
        navigate('/invalidate')
      }
    },

    onError: (err: any) => {
      if (err.response.status === 401) {
        removePasscode()
        removeOrgId()
        navigate('/error')
      }
    },
    onSettled: () => {
      nProgress.done()
    },
  })

  const { refetch: storeInviteRefetch } = useUserStoreInviteInfo(
    {
      enabled: !!inviteParams?.orgId && !!inviteParams?.uuid,
      onSuccess: commonSuccessHandler,
      onError: commonErrorHandler,
    },
    {
      uuid: inviteParams?.uuid,
      orgId: inviteParams?.orgId as string,
    }
  )
  const { refetch: userInviteRefetch } = useUserInviteInfo(
    {
      enabled: !!inviteParams?.uuid && !inviteParams?.orgId,
      onSuccess: commonSuccessHandler,
      onError: commonErrorHandler,
    },
    inviteParams
  )

  const { refetch: InviteAcceptRefetch } = AcceptInfo(
    {
      enabled: false,
      retry: false,
      onSuccess: (data) => {

        window.location.href = `${NAVIGATE[appName as AppType]}${
          data?.organization
        }`
      },
      onError() {
        navigate('/error')
      },
    },
    { uuid: String(uuid), orgId: String(orgId) }
  )
  useEffect(() => {
    if (orgId) {
      storeInviteRefetch()
    } else if (inviteParams.uuid && !orgId) {
      userInviteRefetch()
    } else {
      ;() => null
    }
  }, [orgId, inviteParams])

  useEffect(() => {
    nProgress.start()
  }, [])
  return (
    <>
      <LogoLayout />
    </>
  )
}

export default UserInvite
