import React, { useEffect, useState } from 'react'
import LogoLayout from '@components/Layout/LogoLayout'
import { getSignupRedirectUrl } from '@constants/index'
import { AppType } from '@contracts/index'
import { useLogin } from '@services/auth'
import {
  useAcceptInvite,
  useStoreAcceptInvite,
  useUserInviteInfo,
  useUserStoreInviteInfo,
} from '@services/user'
import { isFromMobile, setPasscode } from '@utils/auth'
import { NAVIGATE } from '@utils/index'
import { Col, Form, Row } from 'antd'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  Button,
  Checkbox,
  FormInputEmail,
  FormInputPassword,
  FormWrapper,
  Text,
  TextGroup,
} from 'yolo-design/components'
import { Color } from 'yolo-design/constants'
import getDeviceId from '@utils/deviceId'
import useSentry from '@hooks/useSentry'

const Signin = () => {
  const [form] = Form.useForm()
  const [checked, setChecked] = useState(false)
  const [searchParams] = useSearchParams()
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()
  const { appName } = useParams()
  const { setUser } = useSentry()
  const [triggerAccept, setTriggerAccept] = useState(false)
  const inviteId = searchParams.get('uuid') || ''
  const commerceUrl = searchParams.get('returnURL') || ''
  const orgId = searchParams.get('orgId') || ''

  const { refetch: storeRefetch } = useUserStoreInviteInfo(
    {
      enabled: false,
      retry: false,
      onSuccess: (data) => {
        form.setFieldsValue({
          username: data?.user,
        })
      },
      onError() {
        navigate('/error')
      },
    },
    {
      uuid: String(inviteId),
      orgId: String(orgId),
    }
  )

  const { refetch: inviteRefetch } = useUserInviteInfo(
    {
      enabled: false,
      retry: false,
      onSuccess: (data) => {
        form.setFieldsValue({
          username: data?.user,
        })
      },
      onError() {
        navigate('/error')
      },
    },
    {
      uuid: String(inviteId),
    }
  )

  const { refetch: userInviteAcceptRefetch } = useAcceptInvite(
    {
      enabled: false,
      retry: false,
      onSuccess: (data) => {
        window.location.href = `${NAVIGATE[appName as AppType]}${
          data?.passcode
        }`
      },
    },
    { uuid: inviteId }
  )
  const { refetch: userStoreAcceptRefetch } = useStoreAcceptInvite(
    {
      enabled: false,
      retry: false,
      onSuccess: () => {
        window.location.href = `${NAVIGATE[appName as AppType]}${orgId}`
      },
    },
    { uuid: inviteId, orgId: orgId }
  )

  const loginPost = useLogin({
    onSuccess: (res) => {
      setUser({ email: res.email, id: res.id })
      if (isFromMobile()) {
        ;(window as any).flutter_inappwebview.callHandler('onAuthenticated', {
          user: res,
          passcode: res?.passcode,
        })
      } else {
        setPasscode(res.passcode)
        setTriggerAccept(true)
        if (!appName) {
          navigate('/')
        } else {
          if (appName === 'tally') {
            window.open(`YoloinTally:user=${JSON.stringify(res)}`)
            window.location.href = '/tally-login-success'
          } else if (commerceUrl) {
            window.location.href = commerceUrl
          } else {
            window.location.href = `${NAVIGATE[appName as AppType]}${orgId}`
          }
        }
      }
    },
    onError: (res: any) => {
      setErrorMessage(res?.response?.data?.error?.message[0])
    },
  })
  const handleFinish = () => {
    const data: any = form.getFieldsValue()
    loginPost?.mutate({
      device_id: getDeviceId(),
      password: data?.password,
      username: data?.username,
    })
  }
  useEffect(() => {
    if (orgId) {
      storeRefetch()
    } else if (inviteId) {
      inviteRefetch()
    } else {
      ;() => null
    }
  }, [inviteRefetch, storeRefetch, orgId])

  useEffect(() => {
    if (triggerAccept) {
      if (orgId) {
        userStoreAcceptRefetch()
      } else {
        userInviteAcceptRefetch()
      }
    }
  }, [triggerAccept, orgId, userStoreAcceptRefetch, userInviteAcceptRefetch])

  return (
    <LogoLayout>
      <div className="d-flex flex-column w-100 align-items-center">
        <span className="text-heading-large fw-bold text-black">
          Welcome Back
        </span>
        <span className="text-body-medium fw-medium text-grey-700 mt-8">
          Login to access your Account
        </span>
        <FormWrapper
          antdFormProps={{
            form: form,
            layout: 'vertical',
            style: { width: '100%', marginTop: '20px' },
            onFinish: handleFinish,
            onFieldsChange: () => {
              setErrorMessage('')
            },
          }}
        >
          <Row
            gutter={[20, 0]}
            style={{ width: '100%', padding: 0, margin: 0 }}
          >
            <Col span={24}>
              <FormInputEmail
                name={'username'}
                title="Email Id"
                required
                disabled={!!inviteId}
              />
            </Col>
            <Col span={24}>
              <FormInputPassword
                name={'password'}
                title="Password"
                inputType={checked ? 'name' : 'password'}
                required
              />
            </Col>
            <Col span={24}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  isChecked={checked}
                  label="Show Password"
                  handleCheckboxChange={() => setChecked(!checked)}
                  setIsChecked={() => ''}
                />
                <Button
                  type="ghost"
                  antdButtonProps={{ onClick: () => navigate('/forget') }}
                  size="large"
                  label="Forgot Password?"
                />
              </div>

              <Button
                antdButtonProps={{
                  htmlType: 'submit',
                  loading: loginPost?.isLoading,
                }}
                style={{ marginTop: '30px' }}
                block
                type="solid"
                size="large"
                label="Log in"
              />
            </Col>
            <Col span={24}>
              <Text
                style={{ marginTop: '8px' }}
                color={Color.indicators.error}
                category="body"
                size="medium"
                weight="medium"
                value={errorMessage}
              />
            </Col>
            <Col span={24}>
              {inviteId ? (
                ''
              ) : (
                <>
                  {' '}
                  {!isFromMobile() && (
                    <TextGroup
                      style={{ marginTop: '16px', justifyContent: 'center' }}
                      category="body"
                      size="small"
                      weight="medium"
                      list={[
                        {
                          value: "Don't Have an account?",
                          color: Color.gray_shades.gray_700,
                        },
                        {
                          value: 'Create new account',
                          isLink: true,
                          link: `${getSignupRedirectUrl(appName as AppType)}`,
                        },
                      ]}
                    />
                  )}
                </>
              )}
            </Col>
          </Row>
        </FormWrapper>
      </div>
    </LogoLayout>
  )
}

export default Signin
