import LogoLayout from '@components/Layout/LogoLayout'
import { IForgetPost } from '@contracts/models/forget'
import { useForget } from '@services/forget'
import { getPasscode } from '@utils/auth'
import { Col, Form, Row, message } from 'antd'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  FormInputEmail,
  FormWrapper,
  Text,
} from 'yolo-design/components'
import { Color } from 'yolo-design/constants'

const ForgetPage = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const token = getPasscode()

  const Forget = useForget({
    onSuccess: () => {
      message.success(
        'Forget password link send to the entered mail successfully'
      )
      navigate('/signin')
    },
    onError(error: any) {
      form.setFields([
        {
          name: 'username',
          errors: error?.response?.data?.error?.message,
        },
      ])
    },
  })

  const handleFormFinish = () => {
    const val = form.getFieldsValue()

    const postData: IForgetPost = {
      ...val,
    }
    Forget.mutate(postData)
  }
  useEffect(() => {
    if (token) {
      navigate('/')
    }
  }, [])
  return (
    <>
      <LogoLayout>
        <div className="d-flex justify-content-center align-items-center h-max-content w-100 flex-column">
          <Text
            category="heading"
            size="large"
            weight="bold"
            value="Forgot your password?"
            color="#000"
          />

          <Text
            category="body"
            size="medium"
            weight="medium"
            value="Enter the email address associated with your account and we’ll send you a link to reset your password."
            color={Color.gray_shades.gray_700}
            // style={{ marginTop: '8px', textAlign: 'center' }}
            className="mt-8 text-center"
          />

          <FormWrapper
            antdFormProps={{
              form: form,
              layout: 'vertical',
              // style: { width: '100%', marginTop: '20px' },

              className: 'd-flex justify-content-center w-100 mt-20',
              onFinish: handleFormFinish,
            }}
          >
            <Row gutter={[20, 0]} className="w-100">
              <Col span={24}>
                <FormInputEmail
                  name={'username'}
                  title="Email Id"
                  placeHolder="example@email.com"
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Email is required',
                    },
                  ]}
                />
              </Col>

              <Col span={24}>
                <Button
                  antdButtonProps={{ htmlType: 'submit', className: 'mt-20' }}
                  // style={{ marginTop: '20px' }}

                  block
                  type="solid"
                  size="large"
                  label="Send password reset link"
                />
              </Col>
            </Row>
          </FormWrapper>
        </div>
      </LogoLayout>
    </>
  )
}
export default ForgetPage
