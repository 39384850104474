import React from 'react'

import AppProvider from '@contexts/App.context'
import QueryClient from '@lib/QueryClient'
import Routes from '@routes/Routes'

import '@assets/scss/style.scss'
import Sentry from '@lib/Sentry'

function App() {
  return (
    <Sentry>
      <QueryClient>
        <AppProvider>
          <Routes />
        </AppProvider>
      </QueryClient>
    </Sentry>
  )
}

export default App

//test
