import React, { FC, useEffect, useState } from 'react'
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { getPasscode, removeOrgId, removePasscode } from '@utils/auth'
import nProgress from 'nprogress'
import { useUserInfo } from '@services/user'
import useSentry from '@hooks/useSentry'

const Middleware: FC = () => {
  const [isLoaded, setIsLoaded] = useState(true)
  const passcode = getPasscode()
  const navigate = useNavigate()
  const { appName = '' } = useParams()
  const [searchParams] = useSearchParams()
  const inviteId = searchParams.get('uuid') || ''
  const returnURL = searchParams.get('returnURL') || ''
  const orgId = searchParams.get('orgId') || ''

  const { setUser } = useSentry()

  // const appQuery = appName ? `&${appName}` : ''

  const inviteQuery = orgId
    ? `&uuid=${inviteId}&orgId=${orgId}`
    : inviteId
    ? `&uuid=${inviteId}`
    : returnURL
    ? `returnURL=${returnURL}`
    : ''

  const navToSignin = () => navigate(`/signin/${appName}?${inviteQuery}`)

  useUserInfo({
    enabled: !!passcode,
    onSuccess: (res) => {
      setIsLoaded(true)

      setUser({ email: res.email, id: res.id })

      navigate(`/`)
    },
    onError: (err: any) => {
      if (err.response.status === 401) {
        removePasscode()
        removeOrgId()
        navToSignin()
      }
    },
    onSettled: () => {
      nProgress.done()
    },
  })

  useEffect(() => {
    nProgress.start()
    if (!passcode) {
      nProgress.done()
      setIsLoaded(true)
      navToSignin()
    }
  }, [passcode])

  return isLoaded ? <Outlet /> : <></>
}

export default Middleware
