import { IChangePasswordRes, IForgetRes } from '@contracts/models/forget'
import { UseMutationType } from '@contracts/query'
import { useMutation } from '@tanstack/react-query'
import * as API from './api'

/*
 TODO: 
 * useForget -> useForgetPassword
 */
export const useForget: UseMutationType<
  Partial<IForgetRes>,
  { username: string }
> = (options) => useMutation(API.postForgetPassword, options)

export const useChangePassword: UseMutationType<
  Partial<IChangePasswordRes>,
  { uuid: string; password: string }
> = (options) => useMutation(API.postChangePassword, options)
