import { ENV } from '@config/app.config'

import {
  IInviteInfo,
  INewRegisterRep,
  INewRegisterReq,
  IUserInfo,
} from '@contracts/models/user'
import { GET, PATCH, POST } from '@lib/AxiosClient'

export const getUserInfo = (): Promise<IUserInfo> =>
  GET({
    url: `${ENV.ORG_URL}core/user-info/`,
  })

export const getInviteUser = (id: string): Promise<IInviteInfo> =>
  GET({
    url: `${ENV.ORG_URL}core/user-invite-validation/${id}/`,
  })

export const getStoreInviteUser = (
  id: string,
  orgId: string
): Promise<IInviteInfo> => {
  return GET({
    url: `${ENV.INVOICE_API_BASE_URL}client/user-invite-validation/${id}/`,
    headers: {
      organization: String(orgId),
    },
  })
}

export const getInviteUserAccept = (id: string): Promise<IInviteInfo> =>
  GET({
    url: `${ENV.ORG_URL}core/user-invite-accept/${id}/`,
  })

export const getStoreInviteUserAccept = (
  id: string,
  orgId: string
): Promise<IInviteInfo> => {
  return PATCH({
    url: `${ENV.INVOICE_API_BASE_URL}client/user-invite-accept/${id}/`,
    headers: {
      organization: orgId,
    },
  })
}

export const postRegisterUser = (data: {
  reqData: INewRegisterReq
  id: string
}): Promise<Partial<INewRegisterRep>> =>
  POST({
    url: `${ENV.ORG_URL}core/invite-user-register/${data?.id}/`,
    data: data.reqData,
  })
export const postStoreRegisterUser = (data: {
  reqData: INewRegisterReq
  id: string
  orgId: string
}): Promise<Partial<INewRegisterRep>> =>
  POST({
    url: `${ENV.INVOICE_API_BASE_URL}client/invite-user-register/${data?.id}/`,
    data: data.reqData,
    headers: {
      organization: data?.orgId,
    },
  })
