export const SALUTATION_LIST = [
  {
    id: '1',
    label: 'Ms.',
  },
  {
    id: '2',
    label: 'Mr.',
  },
]
