import { QueryClientConfig } from '@tanstack/react-query'

export const ENV = {
  API_BASE_URL: import.meta.env.VITE_ACCOUNTS_API_BASE_URL,
  ACCOUNTS_WEB_BASE_URL: import.meta.env.VITE_ACCOUNTS_WEB_BASE_URL,
  MANUFACTURE_WEB_BASE_URL: import.meta.env.VITE_MANUFACTURE_WEB_BASE_URL,
  FIELDFORCE_WEB_BASE_URL: import.meta.env.VITE_FIELDFORCE_WEB_BASE_URL,
  INVENTORY_WEB_BASE_URL: import.meta.env.VITE_INVENTORY_WEB_BASE_URL,
  COMMERCE_WEB_BASE_URL: import.meta.env.VITE_COMMERCE_WEB_BASE_URL,
  INVOICE_WEB_BASE_URL: import.meta.env.VITE_INVOICE_WEB_BASE_URL,
  WEB_DOMAIN: import.meta.env.VITE_WEB_DOMAIN,
  ENV_NAME: import.meta.env.VITE_ENV_NAME,
  ORG_URL: import.meta.env.VITE_ORGANIZATION_API_BASE_URL,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  INVOICE_API_BASE_URL: import.meta.env.VITE_INVOICE_API_BASE_URL,
}

export const API_CONFIG = {
  timeout: 5 * 60 * 1000, // 5 Minutes
  baseURL: ENV.API_BASE_URL,
}

export const QUERY_CONFIG: QueryClientConfig = {
  defaultOptions: {
    queries: {
      ...(ENV.ENV_NAME === 'local'
        ? {
            refetchOnWindowFocus: false,
            staleTime: 1 * 60 * 1000, // 1 Minute
          }
        : {
            refetchOnWindowFocus: true,
            staleTime: 0, // 0 Minute
          }),

      retry: (_, error: any) => {
        const statusCode = error?.response?.status
        const excludeStatus = [401, 404, 403, 500]

        return !excludeStatus.includes(statusCode)
      },
    },
  },
}

export const MASTERS_STALE_TIME: number = 1 * 60 * 60 * 60 // 1 hour

export const SENTRY_APP_NAME = 'Accounts'
